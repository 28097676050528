body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0 0;
}

/* 
#app {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.swiper {
  left: -4vw;
  position: absolute;
  width: 60%;
  max-width: 320px;
  height: 130px;
  margin-top: 30vh;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  padding: 20px;
  text-align: center;
  border: 2px solid #777;
}

.swiper-slide h3 {
  font-size: 2.3rem;
  line-height: 0.1rem;
}

.swiper-slide[data-gender="both"] {
  color: #000;
  /* background: linear-gradient(45deg, #09afe8 0%, #29f499 100%); // heavy on mobile so */
  background-color: #29f499;
}

.swiper-slide[data-gender="masculine"] {
  color: #000;
  background-color: #23d2fd;
}

.swiper-slide[data-gender="feminine"] {
  color: #fff;
  background-color: #ff6d88;
}

.swiper-slide[data-gender="neuter"] {
  background-color: #ddd;
}

.total {
  color: #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}